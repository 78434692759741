export const roles = [
  {
    'id': 1,
    'name': 'STORE_ADMIN'
  },
  {
    'id': 2,
    'name': 'SUPERADMIN'
  },
  {
    'id': 3,
    'name': 'ADMIN'
  },
  {
    'id': 6,
    'name': 'ORDER'
  }
];
